/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_cognito_identity_pool_id": "eu-central-1:ecf8d5a3-e8fc-420a-8600-3ec012a4e5a9",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_liLSS0xaH",
    "aws_user_pools_web_client_id": "28qh9siohsotnandm2rknvmp3m",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://rte2rgrwlbdq7aquojkydzk7rq.appsync-api.eu-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-central-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-xiwkrsvzgbbehigaww5gr3euna",
    "aws_user_files_s3_bucket": "csvexportsstaging-staging",
    "aws_user_files_s3_bucket_region": "eu-central-1"
};


export default awsmobile;
